.app-div {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.tab-div {
  width: 82%;
  height: 100%;
  /* overflow: scroll; */
}

/* cellphone */
@media only screen and (max-width: 600px) {
  
  .app-div {
    display: flex;
    width: 100vw;
    height: 100vh;
  }
  
  .tab-div {
    width: 75%;
    height: 100%;
    overflow: scroll;
  }

}
