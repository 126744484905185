.tech-div {
    animation: fadeInAnimation ease 1.5s;
    display: flex;
    /* justify-content: space-around; */
    /* justify-content: center; */
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    overflow: scroll;
  }

  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
  
  .tech-text {
    font-family: "Josefin Sans", sans-serif;
    font-size: 12pt;
    color: #505050;
    text-shadow: 0.5px 0.5px white;
    padding-left: 1%;
  }
  
  .tech-modal-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  
  .app-img-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .app-img-div p{
    color: grey;
    font-style: italic;
  }
  
  .app-img {
    max-width: 100%;
    height: auto;
    box-shadow: 5px 5px 5px grey;
    border-radius: 10px;
  }

  .app-container{
    width: 38vw;
  }

  @media only screen and (max-width: 600px) {

    .tech-text {
      padding-left: 8%;
    }

    .tech-text h1{
      font-size: 16pt;
    }

    .app-img-div p{
      font-size: 10px;
    }

    .app-container {
      width: 85%;
    }
  }