.nav-div {
    align-items: left;
    max-width: 17%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 40px;
    padding-left: 40px;
    margin-top: 0;
    overflow: hidden;
    background-color: #d6a9a9;
  }
  
  .nav-text {
    color: #505050;
    font-family: "Josefin Sans", sans-serif;
    text-shadow: 0.5px 0.5px white;
  }

  .nav-text h1{
    font-size: 26;
  }

  .nav-text p{
    font-size: 20;
  }

  .nav-bar {
    align-items: left;
    display: flex;
    flex-direction: column;
  }
  
  .nav-button {
    float: left;
    width: 120px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #bebebe;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 25px;
    padding-left: 15px;
    border-radius: 10px;
    color: #505050;
    box-shadow: 2px 2px 5px grey;
  }
  .nav-button:active {
    color: cadetblue;
  }
  
  .nav-button:hover {
    background-color: #f5f5f4;
  }
  
  .network-div {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 8%;
    margin-bottom: 2%;
  }

  .network-div img{
    width: 30px;
  }
  
  @media only screen and (max-width: 600px) {
    .nav-div{
      width: 30%;
      min-height: 100%;
      max-width: auto;
      padding-left: 2.5%;
    }

    .nav-text h1{
      font-size: 16pt;
    }

    .nav-button{
      width: 100px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-right: 15px;
      padding-left: 10px;
    }

    .network-div{
      display: flex;
      justify-content: space-between;
      bottom: 0;
      position: fixed;
      width: 8%;
      margin-bottom: 2%;
    }
    .network-div img{
      width: 20px;
    }
  }