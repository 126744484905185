.bio-component {
  animation: fadeInAnimation ease 1.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

.bio-text {
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 50px;
  padding-left: 50px;
  font-family: "Josefin Sans", sans-serif;
  font-size: 16pt;
  color: #505050;
}

.bio-text p {
  color: grey;
}

.bio-img {
  margin-top: 20px;
  max-height: 500px;
  max-width: 100%;
  height: auto;
  box-shadow: 5px 5px 5px grey;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .bio-text {
    font-size: 10pt;
    padding-right: 15px;
    padding-left: 15px;
  }

  .bio-img {
    margin: 0.5%;
    margin-left: 0%;
    margin-top: 2%;
    margin-bottom: 0%;
    width: 275px;
    box-shadow: 2.5px 2.5px 2.55px grey;
  }

  .bio-component {
    display: flex;
    flex-direction: column;
  }

  .bio-text {
    margin: 0%;
    margin-top: 0%;
  }
}
