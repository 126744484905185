.modalBackground {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.7s ease-in-out;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
  }
  
  .modalShowing-true {
    opacity: 1;
    pointer-events: visible;
  }
  
  .modalInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #1e2939;
    width: 900px;
    max-width: calc(100% - 60px);
    margin: auto;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    color: white;
    text-align: left;
    font-family: "Josefin Sans", sans-serif;
  }
  
  .modalImage img {
    width: 100%;
  }

  .modalImage iframe{
    border: 1px solid white;
  }
  
  .modalText {
    padding-left: 15px;
  }
  
  .modalText p {
    line-height: 150%;
  }
  
  .modalButton {
    padding: 13px 35px;
    border-radius: 10px;
    font-size: 14px;
    background: rgb(218, 203, 123);
    border: none;
  }
  
  .modal-exit {
    position: absolute;
    top: 0;
    right: 0;
  }

  @media only screen and (max-width: 600px) {

    .modalInner {
      width: 550px;
      margin-top: 0%;
    }

    .modalImage iframe{
      width: 200px;
    }

    .modalText h1{
      font-size: 18pt;
    }

    .modalText p{
      font-size: 10pt;
    }

    .modalButton{
      font-size: 10px;
    }
  }