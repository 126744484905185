.music-div {
  animation: fadeInAnimation ease 1.5s;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: scroll;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.music-text {
  width: 25vw;
  margin-right: 10px;
  margin-left: 15px;
  color: #505050;
  text-shadow: 0.5px 0.5px white;
  font-family: "Josefin Sans", sans-serif;
}

.music-text p {
  color: grey;
}

.iframe-div {
  margin-left: 15px;
  width: 75vw;
}

.iframe-div h1{
  color: #505050;
}

.iframe-div iframe{
  margin: 5px;
  border: 1px solid black;
}

.music-iframe {
  border: 0;
  left: 0;
  width: 300px;
  max-width: 100%;
  height: 475px;
}

@media only screen and (max-width: 600px) {
  .music-div {
    display: flex;
    flex-direction: column;
  }
  .iframe-div {
    margin-bottom: 0;
    margin-left: 14%;
  }

  .iframe-div h1 {
    font-size: 16pt;
    padding-left: 1%;
  }

  .music-iframe {
    width: 260px;
    margin: 1%;
    margin-left: 1%;
  }

  .music-text {
    width: 270px;
    margin-left: 5%;
  }
  .music-text h1 {
    font-size: 18pt;
  }

  .music-text p {
    font-size: 10pt;
  }
}
