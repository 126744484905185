.contact-div {
    animation: fadeInAnimation ease 1.5s;
    max-height: 80%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
  }

  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
  
  /* .contact-text {
    text-align: center;
    color: #505050;
  } */
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    width: 60vw;
    font-family: "Josefin Sans", sans-serif;
    margin-top: 2%;
    border: solid 2px;
    margin-left: 10%;
    margin-right: 10%;
    background-color: #d6a9a9;
    border-radius: 10px;
  }

  .contact-form input{
    border-radius: 5px;
    width: 300px;
  }

  .contact-form textarea{
    border-radius: 5px;
    width: 600px;
    height: 600px;
    border: solid 2px;
    font-family: "Josefin Sans", sans-serif;
  }
  
  .contact-label {
    font-size: 14pt;
    padding-bottom: 3px;
  }

  .form-button {
    background-color: #bebebe;
    font-family: "Josefin Sans", sans-serif;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 25px;
    padding-left: 25px;
    border-radius: 10px;
    color: #505050;
    font-family: "Josefin Sans", sans-serif;
    box-shadow: 2px 2px 5px grey;
  }
  
  .contact-label {
    margin-top: 20px;
    color: #505050;
  }

  @media only screen and (max-width: 600px) {
    .contact-form textarea{
      border-radius: 5px;
      width: 200px;
      height: 300px;
    }

    .contact-text h1{
      font-size: 18pt;
    }

    .contact-form input{
      width: 150px
    }
  }